<template>
  <b-modal
    id="login"
    hide-header
    hide-footer
    centered
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    lazy
    visible>
    <div>
      <div
        v-if="loading.state"
        class="container">
        <Loading>
          {{ loading.message }}
        </Loading>
      </div>
      <div 
        v-else
        class="container">
        <h2>CRM Administration Login</h2>
        <b-alert
          v-model="error.state"
          variant="danger">
          {{ error.message }}
        </b-alert>
        <b-form 
          class="w-100 wl-50"
          @submit="onSubmit">
          <b-form-group 
            label="Username / Email address"
            label-for="username"
            label-cols-sm="4">
            <b-form-input
              id="username"
              v-model="username"
              type="text" />
          </b-form-group>
          
          <b-form-group 
            label="Password"
            label-for="password"
            label-cols-sm="4">
            <b-form-input
              id="password"
              v-model="password"
              type="password" />
          </b-form-group>

          <b-form-group 
            label="Server"
            label-for="server"
            label-cols-sm="4">
            <b-form-select
              id="server"
              v-model="$api.serverOption"
              type="password"
              :options="$api.serverOptions" />
          </b-form-group>

          <div class="text-center">
            <b-button type="submit">
              Login
            </b-button>
          </div>
          <b-form />
        </b-form>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Loading from "../components/Loading";
export default {
  name: "LoginModal",
  components: {Loading},
  data() {
    return {
      error: {
        state: false,
        message: "An error occurred"
      },
      loading: {
        state: false,
        message: "logging in.."
      },
      username: "",
      password: ""
    }
  }, methods:{
    onSubmit(evt){
       evt.preventDefault();
      
       if (this.$utils.isNullOrWhitespace(this.username) ||
          this.$utils.isNullOrWhitespace(this.password)) {
        // Missing required information
        this.error.state = true;
        this.error.message = "Username or password is missing.";
        return;
      }
      this.error.state = false;
      this.loading.state = true;
      this.loading.message = "Logging in...";
      this.$store.dispatch("auth/setCredentials", { 
        username: this.username,
        password: this.password
      })
      .then(() => {
          this.loading.state = false;
          this.username = "";
          this.password = "";
      })
      .catch(error => {
        this.username = "";
        this.password = "";
        this.loading.state = false;
        this.error.state = true;
        console.error(JSON.parse(JSON.stringify(error)));
        this.error.message = error;
      });
    }
  }
};
</script>
<style>

</style>
