<template>
  <b-container fluid>
    <b-alert
      v-model="error.state"
      variant="danger"
      dismissible>
      {{ error.message }}
    </b-alert>
    <h1>Companies</h1>
    <div>
      <b-modal
        id="SearchUsermodal"
        hide-header
        hide-footer
        centered
        size="lg"
        no-close-on-backdrop
        no-close-on-esc
        lazy>
        <b-alert
          v-model="error.state"
          variant="danger"
          dismissible>
          {{ error.message }}
        </b-alert>
        <b-button 
          class="btn float-right"
          variant="danger"
          @click="$bvModal.hide('SearchUsermodal')">
          X
        </b-button>
        <h1>{{ selectedCompany.name }} </h1>
        <b v-if="selectedCompany.crm != undefined">
          Users ({{ selectedCompany.crm.users.length }})
        </b>
        <b-input
          id="search-input"
          v-model="userSearch"
          class="mr-2"
          type="search"
          placeholder="Search by username" />
        <b-button @click="toggleBusy">
          Search
        </b-button>

        <b-table
          id="loading-table"
          :fields="userModalfields"
          :busy="isBusy"
          show-empty
          class="mt-3"
          :items="userItems"
          outlined>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template v-slot:cell(crmEnabled)="userCrmData">
            <b-button
              v-if="userCrmData.value != true"
              variant="success" 
              @click="onUserEnableClicked(userCrmData)">
              Enable
            </b-button>
            <b-button
              v-else
              variant="danger"
              @click="onUserEnableClicked(userCrmData)">
              Disable
            </b-button>
          </template>
        </b-table>
      </b-modal>
    </div>
    <div>
      <b-modal
        id="Usermodal"
        hide-header
        hide-footer
        centered
        size="lg"
        no-close-on-backdrop
        no-close-on-esc
        lazy>
        <b-button 
          class="btn float-right"
          variant="danger"
          @click="$bvModal.hide('Usermodal')">
          X
        </b-button>
   
        <h1>{{ selectedCompany.name }} </h1>
        <b v-if="selectedCompany.crm != undefined">
          Users ({{ selectedCompany.crm.users.length }})
        </b>
        <b-button
          class="float-right"
          variant="success"
          @click="showHideModal">
          Add user
        </b-button>
        <b-input
          id="filter-input"
          v-model="filter"
          class="mr-2"
          type="search"
          placeholder="Filter current users" />
       
        <b-table 
          v-if="selectedCompany.crm != undefined"
          id="user-table"
          striped 
          hover
          show-empty
          data-search="true"
          :filter="filter"
          :fields="modalfields"
          :items="selectedCompany.crm.users">
          <template v-slot:cell(links)="sitesRow">
            <div class="d-flex flex-column align-items-center">
              <b-button-group 
                size="sm">
                <b-button
                  v-b-tooltip.hover
                  title="Delete"
                  variant="danger"
                  @click="onUserDisabledClicked(sitesRow.item)">
                  <b-icon icon="trash" />
                </b-button>
              </b-button-group>
            </div>
          </template>
        </b-table>
      </b-modal>
      <b-table 
        id="company-table"
        class="text-left"
        striped 
        hover
        show-empty
        :fields="fields"
        :items="companiesTableProvider">
        <template v-slot:cell(companyLogo)="logo">
          <b-img
            class="company-logo"
            fluid
            thumbnail
            :src="logo.value" />
        </template>
        <template v-slot:cell(crm.enabled)="crmData">
          <b-button
            v-if="crmData.value != true"
            variant="success"
            @click="onCompanyEnableClicked(crmData)">
            Enable
          </b-button>
          <b-button
            v-else
            variant="danger"
            @click="onCompanyEnableClicked(crmData)">
            Disable
          </b-button>
        </template>
        <template v-slot:cell(crm)="data">
          <b-button
            v-if=" data.value.users.length > 0"
            v-b-modal="'Usermodal'"
            variant="success"
            @click="selected(data)">
            Show Users ({{ data.value.users.length }})
          </b-button>
          <b-button
            v-else
            v-b-modal="'Usermodal'"
            variant="danger"
            @click="selected(data)">
            Show Users ({{ data.value.users.length }})
          </b-button>
        </template>
      </b-table>
    </div>
  </b-container>
</template>
<script>

export default {
  name: "Companies",
    data(){
      return {
        isBusy: false,
        error: {
          state: false,
          message: "An error has occurred"
        },
        fields: [
          {key: 'companyLogo', label: "Company Logo" },
          {key: 'name', label: "Company Name" },
          {key: 'crm.enabled', label: "" },
          {key: 'crm', label: "" },
          {key: 'statistics', label: "" },
        ], 
        modalfields: [
          "username", "firstname","surname", "links"
        ],   
        userModalfields: [
          "username", "firstname","surname", "crmEnabled"
        ],
        selectedCompany: '',
        filter: null,
        userItems: [],
        userSearch: '',
      };
    },
    methods:{
      usersProviderTable(ctx){
         this.$store.dispatch("auth/getToken")
         .then(token=> {
            let response = this.$api.getUsers({
            token,
            params:{
              SearchQuery: this.userSearch
            }
          }).then(result => {
            this.userItems = [];  
            if(result != undefined){
              this.userItems = result.response.data;
                this.isBusy = !this.isBusy
            } 

            if(result.isError){
                  this.error.state = true;
                  this.error.message = result.message;
                  this.isBusy = !this.isBusy
                  console.log(JSON.stringify(error));
            }
          }).catch(error => {
            this.error.state = true;
            this.error.message = error;
            this.isBusy = !this.isBusy
            console.log(JSON.stringify(error));
          })
         })
      },
      companiesTableProvider(ctx, callback){
        
        this.$store.dispatch("auth/getToken")
        .then(token => {
          let response = this.$api.getCompanies({
            token,
            params:{
              ShowHidden:true
            }
          }).then(result => {
            let tableData = []
            if(result != undefined){
              tableData = result.response.data;
            }

            if(!result.isError) callback(tableData);
            else{
              this.error.state = true;
              this.error.message = result.message;
              console.log(JSON.stringify(error));
              callback([]);
            }
          }).catch(error => {
            this.error.state = true;
            this.error.message = error;
            console.log(JSON.stringify(error));
            callback([]);
          });
        })
      },
      toggleBusy() {
        this.usersProviderTable();
        this.isBusy = !this.isBusy
      },
      showHideModal: function (){
        this.$bvModal.hide('Usermodal');
        this.$bvModal.show('SearchUsermodal');
      },
      selected: function ($item){
        this.selectedCompany = $item.item;
        //this.Usermodal.visible
      },
      onUserDisabledClicked: function($row){
    
          this.disableUser(this.selectedCompany.id, $row.userID);
        
      },
      onUserEnableClicked: function($row){
        if($row.item.crmEnabled){
          this.disableUser(this.selectedCompany.id, $row.item.id);
        }else{
          this.enableUser(this.selectedCompany.id, $row.item.id);
        }
      },
      onCompanyEnableClicked: function($row){
        if($row.item.crm.enabled){
            this.disableCompany($row.item.id);
        }else{
            this.enabledCompany($row.item.id);
        }
      },
       enableUser: function($companyId, $userId){
          console.log("enable");
           this.$store.dispatch("auth/getToken").then(token => {
              let response = this.$api.addUser($companyId, $userId,{
                token
                }).then(result => {
                  if(result.isError){
                    
                        this.error.state = true;
                        this.error.message = result.message;
                        console.log(JSON.stringify(error));
                          console.log(JSON.stringify(result.status));
                  }else{
                    this.refreshCompanyTableData();
                  }
                }).catch(error => {
                    this.error.state = true;
                    this.error.message = error;
                    console.log(JSON.stringify(error));
                });
            });
       },
       disableUser: function($companyId,$userId){
            this.$store.dispatch("auth/getToken").then(token => {
            let response = this.$api.deleteUser($companyId, $userId,{
                token
                }).then(result => {
                  if(result.isError){
                        this.error.state = true;
                        this.error.message = result.message;
                        console.log(JSON.stringify(error));
                  }else{
                    this.refreshCompanyTableData();
                  }
                }).catch(error => {
                    this.error.state = true;
                    if(JSON.stringify(error).includes("422")){
                       this.error.message = "Error: User is registered with another company";
                    }else{
                      this.error.message = error;
                    }
                    console.log(JSON.stringify(error));
                });
            });
       },
       enabledCompany: async function($companyId){
        this.$store.dispatch("auth/getToken").then(token => 
        {
          let response = this.$api.addCompany($companyId,  {
            token
          }).then(result => {

            if(result.isError){
                  this.error.state = true;
                  this.error.message = result.message;
                  console.log(JSON.stringify(error));
            }else{
              this.refreshCompanyTableData();
            }
          }).catch(error => {
            this.error.state = true;
            this.error.message = error;
            console.log(JSON.stringify(error));
          });
        });
       
      },
      disableCompany: function($companyId){
        this.$store.dispatch("auth/getToken").then(token => 
        {
          let response = this.$api.deleteCompany($companyId,  {
            token
          }).then(result => {

            if(result.isError){
                  this.error.state = true;
                  this.error.message = result.message;
                  console.log(JSON.stringify(error));
            }else{
              this.refreshCompanyTableData();
            }
          }).catch(error => {
            this.error.state = true;
            this.error.message = error;
            console.log(JSON.stringify(error));
          });
        });
      },
      refreshCompanyTableData() {
         this.$root.$emit("bv::refresh::table", "company-table");
         this.$bvModal.hide('Usermodal');
         this.$bvModal.hide('SearchUsermodal');
      },
    }
}
</script>
<style scoped>
.container-fluid h1{
  text-align: left;
  margin: 10px;
}
.company-logo{
  max-width: 6rem;
  max-height: 4rem;
}
</style>