<template>
  <b-navbar
    type="dark"
    variant="dark">
    <b-navbar-nav>
      <b-nav-item :to="{ name: 'Home' }">
        Home
      </b-nav-item>
      <b-nav-item :to="{name: 'Companies'}">
        Companies
      </b-nav-item>
      <b-nav-item :to="{name: 'Users'}">
        Users
      </b-nav-item>
    </b-navbar-nav>

    <b-navbar-nav class="ml-auto">
      <b-nav-item-dropdown v-if="authenticated">
        <template v-slot:button-content>
          <b>{{ username }}</b>
        </template>
        <b-dropdown-item @click="signOut">
          Sign Out
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>
<script>
export default {
    data() {
    return {
      apiVersion: null
    }
  }, 
  computed: {
    authenticated() {
      return this.$store.getters["auth/authenticated"];
    },
    username() {
      return this.$store.getters["auth/username"];
    },
    permissions() {
      return this.$store.getters["auth/permissions"];
    }
  },
 methods: {
    signOut(evt){
      evt.preventDefault();
      this.$store.dispatch("auth/resetStore");
    }
  }
}
</script>
<style>

.ml-auto b{
  color: #787878;
}
</style>
