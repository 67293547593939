<template>
  <b-container fluid>
    <h1>CRM Administration</h1>
  </b-container>
</template>
<script>
export default {
  name: 'Home',
};
</script>

<style scoped>
.container-fluid h1{
  text-align: left;
  margin: 10px;
}
</style>