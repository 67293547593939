import Vue from 'vue';
import Router from 'vue-router';

import Home from '../views/Home.vue';
import Companies from '../views/Companies.vue';
import Users from '../views/Users.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },{
      path: '/',
      name: 'Companies',
      component: Companies
    },{
      path: '/',
      name: 'Users',
      component: Users
    }
  ],
});
