export default {
  install(Vue) {
    Vue.prototype.$utils = {
      isNullOrWhitespace: (input) => !input || !input.trim(),
      copyStringToClipboard: (str) => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style = { position: 'absolute', left: '-9999px' };
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      },
    };
  },
};
