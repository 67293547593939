<template>
  <div class="m-3 d-flex align-items-center justify-content-center">
    <b-spinner class="loading-spinner" />
    <div class="loading-text ml-2">
      <slot>Loading...</slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "Loading"
};
</script>
<style scoped>
.loading-text{
  font-style: italic;
  font-size: 2rem;
}
.loading-spinner{
  width: 2rem; 
  height: 2rem;
}
</style>