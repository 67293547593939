import axios from 'axios';
import {cloneDeep} from 'lodash-es';

const BASE_OPTIONS = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Cache-Control': 'no-cache'
    }
  };

  //Options that are displayed on the login combo box
  const SERVER_COMBO_OPTIONS = [
    { value: "production", text: "Production" },
    { value: "demo", text: "Demo" },
    { value: "dev", text: "Online Development" },
    { value: "localDev", text: "Local Development" }
  ];

  const SERVERS = {
    production: "https://api.newlineasp.com/api",
    demo: "https://apidemo.newlineasp.com/api",
    dev: "https://apidev.newlineasp.com/api",
    localDev: "http://localhost:51222/api"
  };

  let selectedServer = "production";
function handleToken(options, token = null) {
  if (token && token.trim()) {
    options.headers.Authorization = 'Bearer ' + token;
  }
}

function addParams(uri, params = {}) {
    if (params !== null && params !== undefined) {
      let lastchar = uri.slice(-1);
      if (lastchar !== "?" || lastchar !== "&") uri = uri + "?";
      Object.entries(params).forEach(([key, val]) => {
        uri = uri + encodeURIComponent(key) + "=" + encodeURIComponent(val) + "&";
      });
    }
    let lastchar2 = uri.slice(-1);
    if (lastchar2 === "&") 
      return uri.slice(0, -1);
    else
      return uri;
  }

  
function error(msg) {
    return new Error("ApiPlugin: " + msg);
  }
  

  export default {
    install(Vue, vueOptions) {
      Vue.prototype.$api = {
        _selectedServer: "dev",
        get server() { return SERVERS[selectedServer]; },
        get serverOption() { return selectedServer; },
        set serverOption(val) { selectedServer = val; },
        serverOptions: [
          { value: "production", text: "Production" },
          { value: "demo", text: "Demo" },
          { value: "dev", text: "Online Development" },
          { value: "localDev", text: "Local Development" }
        ], getVersion: async () => {
            let uri = encodeURI(SERVERS[selectedServer] + "/System/Version");
            let options = cloneDeep(BASE_OPTIONS);
            let response = await axios.get(uri, options);
            return { response, isError: (response.status >= 400) };
          },
          getToken: async (credentials) => {
            let uri = encodeURI(SERVERS[selectedServer] + "/Token");
            let options = cloneDeep(BASE_OPTIONS);
            let response = await axios.post(uri, credentials, options);
            return { response, isError: (response.status >= 400) };
          }, 
          changePassword: async (credentials, { token = null, params }) => {
            let uri = encodeURI(SERVERS[selectedServer] + "/Users/UpdatePassword");
            uri = addParams(uri, params);
            let options = cloneDeep(BASE_OPTIONS);
            handleToken(options, token);
            let response = await axios.post(uri, credentials, options);
            return { response, isError: (response.status >= 400) };
          },
          getSelf: async ({ token = null, params }) => {
            let uri = encodeURI(SERVERS[selectedServer] + "/Users/Me");
            uri = addParams(uri, params);
            let options = cloneDeep(BASE_OPTIONS);
            handleToken(options, token);
            let response = await axios.get(uri, options);
            return { response, isError: (response.status >= 400) };
          },
          getCompanies: async ({ token = null, params }) => {
            let uri = encodeURI(SERVERS[selectedServer] + "/CRMAdmin/Companies");
            uri = addParams(uri, params);
            let options = cloneDeep(BASE_OPTIONS);
            handleToken(options, token);
            let response = await axios.get(uri, options);
            return { response, isError: (response.status >= 400) };
          },
          getCompany: async (id,{ token = null, params }) => {
            let uri = encodeURI(SERVERS[selectedServer] + "/CRMAdmin/Company/" + id);
            uri = addParams(uri, params);
            let options = cloneDeep(BASE_OPTIONS);
            handleToken(options, token);
            let response = await axios.get(uri, options);
            return { response, isError: (response.status >= 400) };
          }, 
          deleteCompany: async (id, { token = null, params }) => {
            if (!id || !id.trim()) return error("No object ID was specified");
            let uri = encodeURI(SERVERS[selectedServer] + "/CRMAdmin/Companies/" + id);
            let options = cloneDeep(BASE_OPTIONS);
            handleToken(options, token);
            let response = await axios.delete(uri , options);
            return { response, isError: (response.status >= 400) };
          },
          addCompany: async (id, { token = null, params }) => {
            if (!id || !id.trim()) return error("No object ID was specified");
            let uri = encodeURI(SERVERS[selectedServer] + "/CRMAdmin/Companies/" + id);
            let options = cloneDeep(BASE_OPTIONS);
            handleToken(options, token);
            let response = await axios.post(uri, null, options);
            return { response, isError: (response.status >= 400) };
          },
          addUser: async (companyId, userID, { token = null, params }) => {
            if (!companyId || !companyId.trim()) return error("No company ID was specified");
            if (!userID || !userID.trim()) return error("No user ID was specified");
            let uri = encodeURI(SERVERS[selectedServer] + "/CRMAdmin/Companies/" + companyId + "/username/"+userID);
            let options = cloneDeep(BASE_OPTIONS);
            handleToken(options, token);
            let response = await axios.post(uri, null, options);
            return { response, isError: (response.status >= 400) };
          },
          deleteUser: async (companyId, userID, { token = null, params }) => {
            if (!companyId || !companyId.trim()) return error("No company ID was specified");
            if (!userID || !userID.trim()) return error("No user ID was specified");
            let uri = encodeURI(SERVERS[selectedServer] + "/CRMAdmin/Companies/" + companyId + "/username/"+userID);
            uri = addParams(uri, params);
            let options = cloneDeep(BASE_OPTIONS);
            handleToken(options, token);
            let response = await axios.delete(uri, options);
            return { response, isError: (response.status >= 400) };
          },
          getUsers: async ({ token = null, params }) => {
            let uri = encodeURI(SERVERS[selectedServer] + "/Users/Search");
            uri = addParams(uri, params);
            let options = cloneDeep(BASE_OPTIONS);
            handleToken(options, token);
            let response = await axios.get(uri, options);
            return { response, isError: (response.status >= 400) };
          }, 
          getAllUsers: async ({ token = null, params }) => {
            let uri = encodeURI(SERVERS[selectedServer] + "/CRMAdmin/Users");
            console.log(uri);
            let options = cloneDeep(BASE_OPTIONS);
            handleToken(options, token);
            let response = await axios.get(uri, options);
            return { response, isError: (response.status >= 400) };
          },
          deleteHistoryPermissions: async (companyid, userid, { token = null, params }) => {
            if (!companyid || !companyid.trim()) return error("No company ID was specified");
            if (!userid || !userid.trim()) return error("No user ID was specified");    
            let uri = encodeURI(SERVERS[selectedServer] + "/CRMAdmin/Companies/" + companyid+"/History/"+userid);
            console.log(uri);
            let options = cloneDeep(BASE_OPTIONS);
            handleToken(options, token);
            let response = await axios.delete(uri , options);
            return { response, isError: (response.status >= 400) };
          },
          addHistoryPermissions: async (companyId, userID, { token = null, params }) => {
            if (!companyId || !companyId.trim()) return error("No company ID was specified");
            if (!userID || !userID.trim()) return error("No user ID was specified");
            let uri = encodeURI(SERVERS[selectedServer] + "/CRMAdmin/Companies/" + companyId + "/History/"+userID);
            let options = cloneDeep(BASE_OPTIONS);
            handleToken(options, token);
            let response = await axios.post(uri, null, options);
            return { response, isError: (response.status >= 400) };
          },
          deleteLedgerPermissions: async (companyid, userid, { token = null, params }) => {
            if (!companyid || !companyid.trim()) return error("No company ID was specified");
            if (!userid || !userid.trim()) return error("No user ID was specified");           
            let uri = encodeURI(SERVERS[selectedServer] + "/CRMAdmin/Companies/" + companyid+"/Ledger/"+userid);
            console.log(uri);
            let options = cloneDeep(BASE_OPTIONS);
            handleToken(options, token);
            let response = await axios.delete(uri , options);
            return { response, isError: (response.status >= 400) };
          },
          addLedgerPermissions: async (companyId, userID, { token = null, params }) => {
            if (!companyId || !companyId.trim()) return error("No company ID was specified");
            if (!userID || !userID.trim()) return error("No user ID was specified");
            let uri = encodeURI(SERVERS[selectedServer] + "/CRMAdmin/Companies/" + companyId + "/Ledger/"+userID);
            let options = cloneDeep(BASE_OPTIONS);
            handleToken(options, token);
            let response = await axios.post(uri, null, options);
            return { response, isError: (response.status >= 400) };
          }
      }
    }
  };