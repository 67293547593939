
const state = {
  token: null,
  tokenTimestamp: null,
  username: null,
  hash: null,
  permissions: [],
  user: null,
};

const getters = {
  authenticated: (state) => (state.token !== null
          && state.username !== null
          && state.hash !== null),

  token: (state) => state.token,

  username: (state) => state.username,

  user: (state) => state.user,

  permissions: (state) => state.permissions,


};

const actions = {
  async setCredentials(context, { username, password }) {
    // Validate supplied credentials (and store if correct)
    const apiResult = await this._vm.$api.getToken({ username, password });
    if (!apiResult.isError) {
      const { data } = apiResult.response;

      // Response was ok, store result
      context.commit('token', data.token);
      context.commit('credentials', {
        username,
        hash: data.hash,
      });
      
      var permissionBoolean = false;
      context.commit('permissions', data.permissions);

      for(var i=0; i < data.permissions.length; i++){
        if( data.permissions[i].category.toUpperCase() == "CRM ADMIN"){
          permissionBoolean = true
        }
      }
    
      if(permissionBoolean === false){
        context.commit('reset');
        throw new Error(`you do not have the permissions to access admin tool`);
      }
      // Get user information
      const selfResult = await this._vm.$api.getSelf({ token: data.token, params: null });
      if (selfResult.isError) {
        context.commit('reset');
        console.error(JSON.parse(JSON.stringify(selfResult.error)));
        throw new Error(`Error while getting user information: ${selfResult.error.message}`);
      } else {
        context.commit('user', selfResult.response.data);
      }
    } else {
      // Response was an error
      context.commit('reset');
      console.error(JSON.parse(JSON.stringify(apiResult.error)));
      throw new Error(`Error while getting initial token: ${apiResult.error.message}`);
    }
  },
  async getToken(context) {

    if ((new Date().getTime() - context.state.tokenTimestamp) > (600 * 1000)) {

      // It's less than 10 minutes before token expiration, let's refresh it.
      const apiResult = await this._vm.$api.getToken({
        
        username: context.state.username,
        hash: context.state.hash,
      });
      if (!apiResult.isError) {
        const { data } = apiResult.response;
        
        // Response was ok, store result
        context.commit('token', data.token);
        context.commit('credentials', {
          username: context.state.username ,
          hash: data.hash,
        });

        context.commit('permissions', data.permissions);
        // Get user information
        const selfResult = await this._vm.$api.getSelf({ token: data.token, params: null });
        if (selfResult.isError) {
          context.commit('reset');
          console.error(JSON.parse(JSON.stringify(selfResult.error)));
          throw new Error(`Error while getting user information: ${selfResult.error.message}`);
        } else {
          context.commit('user', selfResult.response.data);
        }

        // Return the token
      } else {
        // Response was an error
        context.commit('reset');
        console.error(JSON.parse(JSON.stringify(apiResult.error)));
        throw new Error(`Error while getting new token: ${apiResult.error.message}`);
      }
    }

    return context.state.token;
  },
  async changePassword(context, { username, password, currentPassword }) {
    if (this._vm.$utils.isNullOrWhitespace(username)
          || this._vm.$utils.isNullOrWhitespace(password)
          || this._vm.$utils.isNullOrWhitespace(currentPassword)) { throw new Error('Missing required information'); }

    // Prepare & send request
    const data = {
      username,
      password,
      currentPassword,
    };
    const token = await context.dispatch('getToken');
    const cpResult = await this._vm.$api.changePassword(data, { token, params: null });
    if (cpResult.isError) {
      console.error(JSON.parse(JSON.stringify(cpResult.error)));
      throw new Error(`Error while changing password: ${cpResult.error.message}`);
    }

    // If successful, try to log in using new password (so we have latest hash)
    await context.dispatch('setCredentials', { username, password });
    return context.state.hash;
  },
  resetStore(context) {
    context.commit('reset');
    return true;
  },
};
const mutations = {
  token(state, value) {
    state.token = value;
    state.tokenTimestamp = new Date();
  }, 
  user(state, value) {
    state.user = value;
  },
  credentials(state, { username, hash }) {
    state.username = username;
    state.hash = hash;
  },
  permissions(state, permissions = null) {
    if (permissions === null || permissions.length < 1) { state.permissions = []; } else { state.permissions = permissions; }
  },
  reset(state) {
    state.token = null;
    state.tokenTimestamp = null;
    state.user = null;
    state.username = null;
    state.hash = null;
    state.permissions = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
