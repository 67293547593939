<template>
  <b-container fluid>
    <b-alert
      v-model="error.state"
      variant="danger"
      dismissible>
      {{ error.message }}
    </b-alert>
    <h1>Users</h1>
    <div>
      <b-modal
        id="loginmodal"
        hide-header
        hide-footer
        centered
        size="lg"
        no-close-on-backdrop
        no-close-on-esc
        lazy>
        <b-button 
          class="btn float-right"
          variant="danger"
          @click="$bvModal.hide('loginmodal')">
          X
        </b-button>
   
        <h1>{{ selectedUser.username }} </h1>
       
        <b-table 
          v-if="selectedUser.logins != undefined"
          id="user-table"
          striped 
          hover
          show-empty
          :items="selectedUser.logins"
          :fields="loginfields">
          <template v-slot:cell(lastLogin)="item">
            {{ item.item.lastLogin | moment("MM/DD/YYYY hh:mm") }}
          </template>
        </b-table>
      </b-modal>
    </div>
    <div>
      <b-input
        id="filter-input"
        v-model="filter"
        class="mr-2"
        type="search"
        placeholder="Filter current users" />
       
      <b-table 
        id="user-table"
        class="text-left"
        striped 
        hover
        data-search="true"
        show-empty
        :fields="fields"
        :items="tableData"
        :filter="filter"
        @filtered="onFiltered">
        <template v-slot:cell(logins)="data">
          <b-button
            v-b-modal="'loginmodal'"
            variant="primary"
            @click="selected(data)">
            Show logins
          </b-button>
        </template> 
        <template v-slot:cell(historyEnabled)="history">
          <b-button
            v-if="history.item.historyEnabled != true"
            variant="success"
            @click="toggleHistoryPermissions(history)">
            Enable
          </b-button>
          <b-button
            v-else
            variant="danger"
            @click="toggleHistoryPermissions(history)">
            Disable
          </b-button>
        </template> 
        <template v-slot:cell(ledgerEnabled)="ledger">
          <b-button
            v-if="ledger.item.ledgerEnabled != true"
            variant="success"
            @click="toggleLedgerPermissions(ledger)">
            Enable
          </b-button>
          <b-button
            v-else
            variant="danger"
            @click="toggleLedgerPermissions(ledger)">
            Disable
          </b-button>
        </template> 
      </b-table>
    </div>
  </b-container>
</template>
<script>
export default {
    name: "Users",
     data(){
      return{
         isBusy: false,
        error: {
          state: false,
          message: "An error has occurred"
        },
        filter:null,
        tableData: [],
        selectedUser: '',
        fields: [
          {key: 'firstname', label: "First name" },
          {key: 'surname', label: "Last name" },
          {key: 'username', label: "Username" },
          {key: 'historyEnabled', label: "History enabled" },
          {key: 'ledgerEnabled', label: "Ledger enabled" },
          {key: 'logins', label: "login attempts" },
        ],
         loginfields: [
          {key: 'name', label: "Phone name" },
          {key: 'deviceInfo.manufacturer', label: "Manufacturer" },
          {key: 'model', label: "Model" },
          {key: 'deviceInfo.platform', label: "Platform" },
          {key: 'lastLogin', label: "Last Login" },
        ]
      }
    },
    beforeMount(){
    this.usersTableProvider()
 },
    methods:{   
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      usersTableProvider(ctx, callback){
        
        this.$store.dispatch("auth/getToken")
        .then(token => {
          let response = this.$api.getAllUsers({
            token,
            params:{
              ShowHidden:true
            }
          }).then(result => {
            console.log(result);
            
            if(result != undefined){
              this.tableData = result.response.data;
            }

            if(result.isError){
              this.error.state = true;
              this.error.message = result.message;
              console.log(JSON.stringify(error));
            }
            
          }).catch(error => {
            this.error.state = true;
            this.error.message = error;
            console.log(JSON.stringify(error));
          });
        })
      },
      selected: function ($item){
        this.selectedUser = $item.item;
      },
      toggleLedgerPermissions ($item){
        if($item.item.ledgerEnabled){
          console.log("disabled ledger permissions");
    
           this.$store.dispatch("auth/getToken").then(token => {
            let response = this.$api.deleteLedgerPermissions($item.item.companyID, $item.item.userID,{
                token
                }).then(result => {
                  if(result.isError){
                        this.error.state = true;
                        this.error.message = result.message;
                        console.log(JSON.stringify(error));
                  }else{
                    this.usersTableProvider();
                  }
                }).catch(error => {
                    this.error.state = true;
                    if(JSON.stringify(error).includes("422")){
                       this.error.message = "Error: User is regeisterd with another company";
                    }else{
                      this.error.message = error;
                    }
                    console.log(JSON.stringify(error));
                });
            });
        }else{
          console.log("enabled ledger permissions");
          this.$store.dispatch("auth/getToken").then(token => {
            let response = this.$api.addLedgerPermissions($item.item.companyID, $item.item.userID,{
                token
                }).then(result => {
                  if(result.isError){
                        this.error.state = true;
                        this.error.message = result.message;
                        console.log(JSON.stringify(error));
                  }else{
                    this.usersTableProvider();
                  }
                }).catch(error => {
                    this.error.state = true;
                    if(JSON.stringify(error).includes("422")){
                       this.error.message = "Error: User is registered with another company";
                    }else{
                      this.error.message = error;
                    }
                    console.log(JSON.stringify(error));
                });
            });
        }
      },
      toggleHistoryPermissions($item){
         if($item.item.historyEnabled){
          console.log("disabled history permissions");

          this.$store.dispatch("auth/getToken").then(token => {
            let response = this.$api.deleteHistoryPermissions($item.item.companyID, $item.item.userID,{
                token
                }).then(result => {
                  if(result.isError){
                        this.error.state = true;
                        this.error.message = result.message;
                        console.log(JSON.stringify(error));
                  }else{
                    this.usersTableProvider();
                  }
                }).catch(error => {
                    this.error.state = true;
                    if(JSON.stringify(error).includes("422")){
                       this.error.message = "Error: User is regeisterd with another company";
                    }else{
                      this.error.message = error;
                    }
                    console.log(JSON.stringify(error));
                });
            });
        }else{
          console.log("enabled history permissions");
          this.$store.dispatch("auth/getToken").then(token => {
            let response = this.$api.addHistoryPermissions($item.item.companyID, $item.item.userID,{
                token
                }).then(result => {
                  if(result.isError){
                        this.error.state = true;
                        this.error.message = result.message;
                        console.log(JSON.stringify(error));
                  }else{
                    this.usersTableProvider();
                  }
                }).catch(error => {
                    this.error.state = true;
                    if(JSON.stringify(error).includes("422")){
                       this.error.message = "Error: User is registered with another company";
                    }else{
                      this.error.message = error;
                    }
                    console.log(JSON.stringify(error));
                });
            });
        }
      },
    }
}
</script>
<style scoped>
.container-fluid h1{
  text-align: left;
  margin: 10px;
}
</style>