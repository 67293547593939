<template>
  <div id="app">
    <template v-if="authenticated">
      <Navbar id="nav" />
      <div
        id="content"
        class="mt-2">
        <router-view />
      </div>
    </template>
    <LoginModal v-else />
  </div>
</template>
<script>
import LoginModal from './components/LoginModal.vue';
import Navbar from './components/NavBar.vue';


export default {
  name: 'App',
  components: {LoginModal, Navbar},
  computed: {
    authenticated() {
      return this.$store.getters['auth/authenticated'];
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
