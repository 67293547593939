import Vue from 'vue';
// Bootstrap import
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import App from './App.vue';

import router from './router';
import store from './store';

// Custom local plugins import
import ApiPlugin from './plugins/api';
import UtilsPlugin from './plugins/utils';

// Bootstrap
import './assets/bootstrap_overrides.scss';
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// Custom local plugins
Vue.use(ApiPlugin);
Vue.use(UtilsPlugin);
Vue.use(require('vue-moment'));
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
